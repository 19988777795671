import { useEvent } from '/machinery/useEvent'

export function useWindowResize({ onResize }) {
  const onResizeEvent = useEvent(onResize)

  React.useEffect(
    () => {
      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }

      function handleResize() {
        onResizeEvent({ width: window.innerWidth })
      }
    },
    [onResizeEvent]
  )
}
