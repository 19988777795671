import { useEvent } from './useEvent'

/* Using signals with functions as values does not work */

/**
 * @template T
 * @typedef {{
 *   get(): T
 *   subscribe(callback: (T) => void): () => void
 * }} Signal
 */

/**
 * @template T
 * @param {T | (() => T)} initialValue
 * @returns {[Signal<T>, (T) => void]}
 */
export function useSignal(initialValue) {
  const initialValueRef = React.useRef(initialValue)
  const { signal, setValue } = React.useMemo(() => createSignal(initialValueRef.current), [])
  return [signal, setValue]
}

export function useDerivedSignal(signal$, derive) {
  const [signal, setValue] = useSignal(derive(signal$.get()))

  useOnSignalChange({ ifChanged: signal$, callback: value => setValue(derive(value)) })

  return signal
}

/**
 * @template T
 * @template X
 * @param {Signal<T>} signal$
 * @param {(T) => Array<X>} derive
 */
export function useDerivedSigals(signal$, derive) {
  const deriveRef = React.useRef(derive)
  const derivedSignals = React.useMemo(
    () => deriveRef.current(signal$.get()).map(x => createSignal(x)),
    [signal$]
  )

  const readOnlySignals = React.useMemo(
    () => derivedSignals.map(x => x.signal),
    [derivedSignals]
  )

  useOnSignalChange({ ifChanged: signal$, callback: value =>
    derive(value).forEach((x, i) => derivedSignals[i].setValue(x))
  })

  return readOnlySignals
}

export function useSignalValue(signal$) {
  const [value, setValue] = React.useState(signal$.get())

  React.useEffect(
    () => {
      setValue(signal$.get())
      const unsubscribe = signal$.subscribe(setValue)
      return unsubscribe
    },
    [signal$]
  )

  return value
}

export function useOnSignalChange({ ifChanged, callback }) {
  if (!ifChanged) throw new Error(`Expected signal, ifChanged is empty`)
  const callbackEvent = useEvent(callback)

  React.useEffect(
    () => {
      const unsubscribe = ifChanged.subscribe(callbackEvent)
      return unsubscribe
    },
    [ifChanged, callbackEvent]
  )
}

/**
 * @template T
 * @param {T | (() => T)} initialValue
 * @returns {{ signal: Signal<T>, setValue: (T) => void }}
 */
function createSignal(initialValue) {
  let value = isCallable(initialValue) ? initialValue() : initialValue
  let listeners = []

  return {
    signal: {
      get() {
        return value
      },
      subscribe(callback) {
        listeners.push(callback)
        return function unsubscribe() {
          const index = listeners.indexOf(callback)
          if (index < 0) return

          listeners.splice(index, 1)
        }
      }
    },

    setValue(newValue) {
      if (newValue === value) return
      value = newValue
      setImmediate(() => {
        listeners.forEach(callback => callback(value))
      })
    },
  }
}

/**
 * @template X
 * @template {() => X} T
 * @param {unknown} value
 * @returns {value is T}
 */
function isCallable(value) {
  return typeof value === 'function'
}
