import { ContentOverlay } from '@kaliber/rabobank-components'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { clearHash, useHash } from '/machinery/useHash'
import { useSignalValue } from '/machinery/signals'
import { useReportError } from '/machinery/ReportError'
import { useClientConfig } from '/machinery/ClientConfig'
import { useHistory } from '@kaliber/routing'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { routeMap } from '/routeMap'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { Loader } from '/features/buildingBlocks/Loader'
import { TopicOverlayContent } from '/features/pageOnly/topics/TopicOverlayContent'

import styles from './TopicContentOverlay.css'

export function TopicContentOverlay() {
  const { __ } = useTranslate()
  const history = useHistory()
  const language = useLanguage()
  const config = useClientConfig()
  const { currentHash$ } = useHash()
  const hash = useSignalValue(currentHash$)
  const isOpen = hash !== ''
  const { topic, isError, isFetching } = useFetchTopic()

  return (
    <ContentOverlay
      copyAriaLabel={__`copy-to-clipboard`}
      closeAriaLabel={__`close-overlay`}
      ContainerElement={ContainerMd}
      {...{ isOpen, handleClose, handleOpenChange, handleCopyToClipBoard }}
    >
      {isFetching ? (
        <div className={styles._rootLoader}>
          <Loader layoutClassName={styles.loaderLayout} />
        </div>
      ) : topic ? (
        <TopicOverlayContent {...{ topic }} />
      ) : (
        // TODO: Render error
        <p>Oeps er gaat iets mis</p>
      )}

    </ContentOverlay>
  )

  function handleClose() {
    clearHash({ history })
  }

  function handleCopyToClipBoard() {
    const url = [`${config.host}/${language}/${routeMap.app.topics.path[language]}`, hash].join('#')
    navigator.clipboard.writeText(url).then().catch(e => reportError(e))
  }

  function handleOpenChange() {
    clearHash({ history })
  }
}

function useFetchTopic() {
  const reportError = useReportError()
  const { currentHash$ } = useHash()
  const hash = useSignalValue(currentHash$)
  const language = useLanguage()

  const { data, isError, isSuccess, isFetching } = useQuery({
    queryKey: [hash],
    queryFn: () => fetchTopic({ slug: hash, language, reportError }),
    placeholderData: keepPreviousData,
    retry: 0,
    enabled: hash !== '',
  })

  const topic = data?.doc ?? undefined

  return { topic, isError, isSuccess, isFetching }
}

async function fetchTopic({ slug, language, reportError }) {
  try {
    return fetchWithResponseHandler(routeMap.api.v1.topic(), {
      method: 'POST',
      body: JSON.stringify({
        slug,
        language,
      })
    })
  } catch (e) {
    reportError(e)
  }
}
