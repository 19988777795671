import { useWindowResize } from '/machinery/useWindowResize'
import { ContainerMd } from '/features/buildingBlocks/Container'
import {
  CardArticle,
  CardVideo,
  CardFact,
  CardPodcastSmall,
  FilterButtons,
  SearchBar
} from '@kaliber/rabobank-components'
import { useQueryString } from '@kaliber/use-query-string'
import { useNormalizedTopicsFilterValues } from '/machinery/useNormalizedFilterValues'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useReportError } from '/machinery/ReportError'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { TOPICS_PER_PAGE, routeMap } from '/routeMap'
import { mapTopicTags } from '/machinery/topics'
import { ensureInteger } from '/machinery/ensureValue'
import { useIsIntersecting } from '/machinery/useIsIntersecting'
import { ButtonTertiary } from '/features/buildingBlocks/Button'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { useMediaQuery } from '@kaliber/use-media-query'
import { MobileFilters } from '/features/buildingBlocks/MobileFilters'
import { useTopicFiltersWithValues } from '/machinery/useFiltersWithValues'
import { useThrottle } from '/machinery/useThrottle'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import media from '/cssGlobal/media.css'
import styles from './TopicsGridAndFilter.css'

const SCROLLING_PAGES_THRESHOLD = 3

export function TopicsGridAndFilter({ filters, layoutClassName }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const [queryString, setQueryString] = useQueryString()
  const normalizedValues = useNormalizedTopicsFilterValues(queryString)
  const { topics, totalTopics, isFetching } = useFetchTopics({
    normalizedValues, language
  })
  const { columns, count: columnCount } = useDistributedColumns({ data: topics })
  const totalNumberOfPages = totalTopics ? Math.ceil(totalTopics / TOPICS_PER_PAGE) : 0

  const gridBottomRef = React.useRef(null)
  const currentPage = ensureInteger(queryString.page, 1)

  useIsIntersecting({
    ref: gridBottomRef,
    enabled: canLoadMoreItemsOnScroll(),
    callback: (isVisible) => loadNextPage(isVisible)
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ContainerMd layoutClassName={styles.filtersContainerLayout}>
        <TopicFilters {...{ filters, normalizedValues, totalTopics }} />
      </ContainerMd>

      <ContainerMd layoutClassName={styles.gridLayout} dataX='topics-grid'>
        {topics?.length ? (
          <div
            className={cx(styles.grid, isFetching && styles.loading)}
            style={{ '--grid-column-count': columnCount }}
          >
            {columns?.map((column, index) => (
              <div className={styles.column} key={index}>
                <Column
                  {...{ column }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className={cx(styles.noResults, isFetching && styles.loading)}>
            <NoResults layoutClassName={styles.noResultsLayout} />
          </div>
        )}
      </ContainerMd>

      {/* TODO: RABOKJP-1009 - Render no results indicator */}

      {showLoadMoreButton() && (
        <div className={styles.loadMore}>
          <ContainerMd>
            <div className={styles.buttonWrapper}>
              <ButtonTertiary
                dataX='load-more'
                onClick={loadNextPage}
                label={__`load-more`}
              />
            </div>
          </ContainerMd>
        </div>
      )}

      <div ref={gridBottomRef} />
    </div>
  )

  function showLoadMoreButton() {
    return currentPage >= SCROLLING_PAGES_THRESHOLD
      && currentPage < totalNumberOfPages
  }

  function canLoadMoreItemsOnScroll() {
    return !isFetching
      && Boolean(topics?.length)
      && currentPage < SCROLLING_PAGES_THRESHOLD
      && currentPage < totalNumberOfPages
  }

  function loadNextPage(isVisible) {
    if (!isVisible) return

    // @ts-ignore
    setQueryString(x => ({ ...x, page: currentPage + 1 }))
  }
}

function NoResults({ layoutClassName }) {
  const { __ } = useTranslate()
  const [, setQueryString] = useQueryString()

  return (
    <section className={cx(styles.componentNoResults, layoutClassName)} data-x='no-results'>
      <HeadingMd h={2} title={__`topics-grid-no-results`} />
      <ButtonTertiary
        dataX='reset-filters'
        layoutClassName={styles.buttonLayout}
        label={__`reset-filters-label`}
        onClick={handleClick}
      />
    </section>
  )

  function handleClick() {
    setQueryString({})
  }
}

function TopicFilters({ filters, normalizedValues, totalTopics }) {
  const { q, tag } = normalizedValues
  const isViewportMd = useMediaQuery(media.viewportMd)
  const filtersAndValues = useTopicFiltersWithValues({ filters, values: normalizedValues })
  const [, setQueryString] = useQueryString()
  const { __ } = useTranslate()

  return (
    <div data-style-context="light">
      {!isViewportMd && (
        <MobileFilters
          searchQuery={q}
          totalCount={totalTopics}
          handleResetClick={handleReset}
          onSearch={handleSearchChange}
          placeholder={__`topics-search-field-placeholder`}
          {...{ handleFilterChange, filtersAndValues }}
        />
      )}
      {isViewportMd && (
        <div className={styles.topicFilters}>
          <SearchFilter value={q} />
          {filters.map((filter, i) => {
            switch (filter.id) {
              case 'tag': return <TagFilter key={i} tags={filter.options} currentTags={tag} />
              default: throw new Error('Unsupported filter type provided')
            }
          })}
        </div>
      )}
    </div>
  )

  function handleSearchChange(q) {
    handleFilterChange({ q })
  }

  function handleReset() {
    setQueryString({})
  }

  function handleFilterChange(filterChanges) {
    setQueryString(x => ({ ...x, ...filterChanges, page: null }))
  }
}

function SearchFilter({ value }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const [, setQueryString] = useQueryString()
  const [inputValue, setInputValue] = React.useState(value)
  const { data: searchSuggestions, isSuccess } = useSearchSuggestions({ input: inputValue, language })

  return (
    <SearchBar
      placeholder={__`topics-search-field-placeholder`}
      queryStringValue={value}
      onClear={handleClear}
      onSubmit={handleSubmit}
      onChange={handleChange}
      suggestionsFetched={isSuccess}
      suggestions={searchSuggestions}
      {...{ inputValue }}
    />
  )

  function handleChange(e) {
    setInputValue(e.currentTarget.value)
  }

  function handleSubmit(e, input) {
    e.preventDefault()
    pushToDataLayer({
      event: 'filtered',
      metadata: {
        user: {
          filter: { searchterm: input }
        }
      }
    })
    changeQueryString({ input })
  }

  function handleClear(input) {
    setInputValue('')
    changeQueryString(input)
  }

  function changeQueryString({ input }) {
    setQueryString(x => ({ ...x, q: input, page: null }))
  }
}

function TagFilter({ tags, currentTags }) {
  const [, setQueryString] = useQueryString()

  return (
    <div className={styles.componentTagFilter}>
      <FilterButtons
        activeItems={currentTags}
        items={mapTags(tags)}
        {...{ handleClick }}
      />
    </div>
  )

  function mapTags(tags) {
    return tags.map(tag => {
      return {
        title: tag.label,
        key: tag.id
      }
    })
  }

  function handleClick(tag) {
    const slug = tag.key
    setQueryString((x) => ({
      ...x,
      page: null,
      tag: currentTags.includes(slug)
        ? currentTags.filter(x => x !== slug)
        : currentTags.concat(slug)
    }))
  }
}

function Column({ column }) {
  return column.map((doc, index) => (
    <GridItem key={index} data={doc._source} />
  ))
}

function GridArticleCard({ data }) {
  const language = useLanguage()
  const { title, subtitle, tag, hero } = data
  const tags = mapTopicTags({ tags: tag, language })
  return <CardArticle {...{ title, subtitle, tags, hero }} />
}

function GridVideoCard({ data }) {
  const { title, video } = data
  return <CardVideo {...{ title, video }} />
}

function GridFactCard({ data }) {
  const language = useLanguage()
  const { tag, fact, createdAt } = data
  const tags = mapTopicTags({ tags: tag, language })
  return <CardFact {...{ tags, fact, createdAt }} />
}

function GridPodcastCard({ data }) {
  const { title, podcast } = data ?? {}
  return <CardPodcastSmall {...{ title, podcast }} />
}

function GridItem({ data }) {
  const language = useLanguage()
  const cardsContentRenderers = {
    'topicArticle': GridArticleCard,
    'topicVideo': GridVideoCard,
    'topicFact': GridFactCard,
    'topicPodcast': GridPodcastCard,
  }
  const ContentComponent = renderCardType({ docType: data.type })
  const dataX = data.type === 'topicPodcast' ? 'link-to-podcast' : 'link-to-article-overlay'

  return ContentComponent !== undefined && (
    <a
      href={`#${data.slug.current}`}
      className={styles.componentGridItem}
      aria-label={data.title}
      data-x={dataX}
      onClick={trackArticleClick}
    >
      <ContentComponent {...{ data }} />
    </a>
  )

  function trackArticleClick() {
    pushToDataLayer({
      event: 'highlighted_slide',
      metadata: {
        content: {
          type: data.type,
          title: data.title,
          tag: data?.tag !== null
            ? data.tag.map(item => item.code)
            : [],
          language,
          datecreated: data.createdAt
        }
      }
    })
  }

  function renderCardType({ docType }) {
    return cardsContentRenderers[docType]
  }
}

function useDistributedColumns({ data }) {
  const [count, setCount] = React.useState(2)

  useWindowResize({
    onResize: ({ width }) => {
      if (width > 0) setCount(1)
      if (width > parseFloat(media.breakpointMd)) setCount(2)
      if (width > parseFloat(media.breakpointLg)) setCount(3)
    }
  })

  const columns = React.useMemo(
    () => distributeColumns({ items: data ?? [], count }),
    [data, count]
  )

  return {
    columns,
    count,
  }
}

function distributeColumns({ items, count }) {
  const columns = Array.from({ length: count }, () => [])

  return items.reduce((result, x, i) => {
    result[i % count].push(x)

    return result
  }, columns)
}

function useFetchTopics({ normalizedValues, language }) {
  const reportError = useReportError()

  const { data, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['topics', normalizedValues],
    queryFn: () => fetchTopics({ values: normalizedValues, language, reportError }),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: 0,
  })

  const totalTopics = data?.hits?.total?.value ?? 0
  const topics = data?.hits?.hits ?? []

  return { topics, totalTopics, isError, isSuccess, isFetching }
}

async function fetchTopics({ values, language, reportError }) {
  const { page, ...filters } = values

  try {
    return fetchWithResponseHandler(routeMap.api.v1.topics(), {
      method: 'POST',
      body: JSON.stringify({
        filters,
        language,
        page
      })
    })
  } catch (e) {
    reportError(e)
  }
}

function useSearchSuggestions({ input, language }) {
  const reportError = useReportError()
  const throttledInput = useThrottle(input, 500)

  const { data, isSuccess, isError, isLoading } = useQuery({
    enabled: throttledInput.length >= 2,
    queryKey: ['suggestions', { throttledInput }],
    queryFn: () => fetchSearchSuggestions({ input: throttledInput, language, reportError }),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 0,
  })

  return {
    isSuccess,
    isError,
    isLoading,
    data: isSuccess ? convertSearchSuggestions(data) : []
  }
}

async function fetchSearchSuggestions({ input, language, reportError }) {
  try {
    return fetchWithResponseHandler(routeMap.api.v1.topicsSuggestions(), {
      method: 'POST',
      body: JSON.stringify({ input, language, size: 10 })
    })
  } catch (e) {
    reportError(e)
  }
}

function convertSearchSuggestions(data) {
  const entries = data?.hits?.hits ?? []

  return entries.flatMap(doc => Object.values(doc?.highlight)
    .flatMap(highlights => highlights.map((highlight) => mapHighlight(highlight, doc)))
  )

  function mapHighlight(highlight, doc) {
    return { highlight, href: `#${doc._source?.slug?.current}` }
  }
}
