import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { TopicsGridAndFilter } from './TopicsGridAndFilter'
import { TopicContentOverlay } from '/features/pageOnly/topics/TopicContentOverlay'
import { Loader } from '/features/buildingBlocks/Loader'

import styles from './TopicsGridAndFilterApp.css'

const client = new QueryClient()

export default function TopicsGridAndFilterApp({ filters, layoutClassName }) {
  const isMounted = useRenderOnMount()

  if (!isMounted) return (
    <div className={styles.component_root}>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )

  return (
    <QueryClientProvider {...{ client }}>
      <TopicContentOverlay />
      <TopicsGridAndFilter {...{ filters, layoutClassName }} />
    </QueryClientProvider>
  )
}
