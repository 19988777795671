export function useIsIntersecting({ ref, enabled, callback }) {
  const observerRef = React.useRef(null)

  React.useEffect(
    () => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        callback(entry.isIntersecting)
      )
    },
    [callback]
  )

  React.useEffect(
    () => {
      if (!enabled) return
      observerRef.current.observe(ref.current)

      return () => {
        observerRef.current.disconnect()
      }
    },
    [ref, enabled]
  )
}
