import { MetaData } from '@kaliber/rabobank-components'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { mapTopicTags } from '/machinery/topics'
import { useLanguage } from '/machinery/I18n'

import styles from './TopicHeader.css'

export function TopicHeader({ title, date, tags }) {
  const language = useLanguage()
  const mappedTags = mapTopicTags({ tags, language })

  return (
    <div className={styles.component}>
      <MetaData
        layoutClassName={styles.metaDataLayout}
        tags={mappedTags}
        {...{ date }}
      />
      <div className={styles.title}>
        <HeadingLg h={1} {...{ title }} />
      </div>
    </div>
  )
}
