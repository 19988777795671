import { VideoWithChapters, CardPodcastLarge, CardFact } from '@kaliber/rabobank-components'
import { useTranslate } from '/machinery/I18n'
import { ImageCover } from '/features/buildingBlocks/Image'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import styles from './TopicSubHeader.css'

export function TopicSubHeader({ topic, layoutClassName = undefined }) {
  if (!hasVideo(topic) && !hasFact(topic)) {
    return null
  }

  return (
    <SubHeaderBase {...{ topic, layoutClassName }} ContentRenderer={DefaultSubHeaderContentRenderer} />
  )
}

export function TopicSubHeaderDetailPage({ topic, layoutClassName = undefined }) {
  if (!hasVideo(topic) && !hasFact(topic) && !hasHero(topic) && !hasPodcast(topic)) {
    return null
  }

  return (
    <SubHeaderBase {...{ topic, layoutClassName }} ContentRenderer={SubHeaderContentDetailpage} />
  )
}

function SubHeaderBase({ topic, ContentRenderer, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentSubHeaderBase, layoutClassName)}>
      <ContentRenderer {...{ topic }} />
    </div>
  )
}

function DefaultSubHeaderContentRenderer({ topic }) {
  switch (topic._type) {
    case 'topicVideo':
      return <ChaptersVideo video={topic.videoWithChapters} />
    case 'topicFact':
      return <CardFact fact={topic.fact} scaleOnHover={false} />
    default:
      throw new Error('Unsupported sub header topic type provided.')
  }
}

function ChaptersVideo({ video }) {
  const { __ } = useTranslate()

  return (
    <VideoWithChapters
      chaptersTitle={__`video-chapters-header`}
      onPlay={handlePlay}
      onPause={handlePause}
      onNavigation={handleNavigation}
      {...{ video }}
    />
  )

  function handleNavigation() {
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: {
          title: 'video chapter',
          type: 'click',
        }
      }
    })
  }

  function handlePlay(mediaMetadata) {
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: {
          title: 'video',
          action: 'playing',
          type: 'click',
        },
        media: mediaMetadata
      }
    })
  }

  function handlePause(mediaMetadata) {
    pushToDataLayer({
      event: 'interaction',
      metadata: {
        interaction: {
          title: 'video',
          action: 'paused',
          type: 'click',
        },
        media: mediaMetadata
      }
    })
  }
}

function SubHeaderContentDetailpage({ topic }) {
  switch (topic._type) {
    case 'topicArticle':
      return <ImageCover aspectRatio={16 / 9} image={topic.hero} />
    case 'topicPodcast':
      return <CardPodcastLarge title={topic.title} podcast={topic.podcast} />
    default:
      return <DefaultSubHeaderContentRenderer {...{ topic }} />
  }
}

function hasVideo(topic) {
  return topic?.videoWithChapters
}

function hasFact(topic) {
  return topic?.fact
}

function hasHero(topic) {
  return topic?.hero?.asset
}

function hasPodcast(topic) {
  return topic?.podcast
}
