import { CallToActionLink } from '@kaliber/rabobank-components'
import { TopicHeader } from '/features/buildingBlocks/topic/TopicHeader'
import { ContentTopic } from '/features/buildingBlocks/Content'
import { resolveLinkObjectToHref } from '/machinery/link'
import { TopicSubHeader } from '/features/buildingBlocks/topic/TopicSubHeader'

import styles from './TopicOverlayContent.css'

export function TopicOverlayContent({ topic }) {
  const { content, title, tag, _createdAt, readMore } = topic
  const href = resolveLinkObjectToHref(readMore)

  return (
    <article className={styles.component} data-style-context='light'>
      <TopicHeader {...{ title, date: _createdAt, tags: tag }} />
      <TopicSubHeader layoutClassName={styles.subHeaderLayout} {...{ topic }} />

      {Boolean(content?.length) && <ContentTopic {...{ content }} />}

      {href && (
        <div className={styles.link}>
          <CallToActionLink title={readMore.label} dataX="link" {...{ href }} />
        </div>
      )}
    </article>
  )
}
