import { useHistory } from '@kaliber/routing'
import { useSignal } from '/machinery/signals'

export function useHash() {
  const history = useHistory()

  const [currentHash$, setter] = useSignal(safeHash(history.location.hash))

  React.useEffect(
    () => history.listen(x => setter(safeHash(x.location.hash))),
    [history, setter]
  )

  return {
    currentHash$
  }
}

export function safeHash(x) {
  return x.slice(1)
}

export function clearHash({ history }) {
  history.navigate(`${history.location.pathname}${history.location.search}`)
}

export function hasHashValue(x) {
  return Boolean(safeHash(x).length)
}
